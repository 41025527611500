/* You can add global styles to this file, and also import other style files */
@import "./browser-reset.scss";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    /* COLOR */
    --color-interface-background-primary: #FFF;
}


body,
html {
    background-color: #F5F5F5;
}

* {
    font-family: 'DM Sans', sans-serif;
    font-optical-sizing: auto;
    box-sizing: border-box;
}


.contentfulRichText {
    p {
        font-family: 'DM Sans', sans-serif;
        line-height: 1.4;
        font-size: 1.1rem;
        margin-bottom: 1rem;

        b {
            font-weight: bold;
        }

    }

    h1 {
        text-transform: uppercase;
        font-family: 'DM Sans', sans-serif;
        font-size: 2rem;
        color: var(--dark-blue);
        margin-bottom: .4rem;
        font-weight: bold;
    }

    h2 {
        text-transform: uppercase;
        font-family: 'DM Sans', sans-serif;
        font-size: 1.5rem;
        color: var(--dark-blue);
        margin-bottom: .4rem;
        font-weight: bold;
    }

    h3 {
        text-transform: uppercase;
        font-family: 'DM Sans', sans-serif;
        font-size: 1.2rem;
        color: var(--dark-blue);
        margin-bottom: .4rem;
        font-weight: bold;
    }

    h4 {
        text-transform: uppercase;
        font-family: 'DM Sans', sans-serif;
        font-size: 1.1rem;
        color: var(--dark-blue);
        margin-bottom: .3rem;
        font-weight: bold;
    }

    h5 {
        text-transform: uppercase;
        font-family: 'DM Sans', sans-serif;
        font-size: 1rem;
        color: var(--dark-blue);
        margin-bottom: .3rem;
        font-weight: bold;
    }

    i,
    code {
        font-style: italic;
        text-transform: none;
        color: #86bc24;
        font-family: 'DM Sans', sans-serif;
        line-height: 1.25;

    }

    .richTextImage,
    .richTextVideo {
        max-width: 70vw;
        display: block;
        margin: 40px auto;
        max-height: 70vh;
    }

    .richTextVideo {
        max-height: 50vh;
        max-height: 70vh;
    }

    ul, ul>li{
        list-style-type: disc;
        margin-left: 1em;
    }

    ol, ol>li{
        list-style-type: decimal;
        margin-left: 1em;
    }
}


p {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
}

a,
a:visited,
a:active {
    color: #126C34;
    text-decoration: underline;
}


.button {
    background-color: #178841;
    color: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    border: 0;
    display: flex;
    cursor: pointer;
}

.page-body-wrapper {
    background-color: rgb(0 0 0 / 2%);
    padding: 60px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .page-body-content-full-width {
        max-width: 1300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        width: 100%;
    }

    .page-body-content {
        max-width: 1300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        padding: 0px 100px;
        width: 100%;
    }
}

.text-center {
    text-align: center;
}

.button {
    display: flex;
    background-color: #178841;
    color: #fff;
    border-radius: 8px;
    padding: 12px 16px;
    border: 0;
    gap: 10px;
    align-items: center;
    margin-top: 16px;
    font-size: 16px;
}

.chip {
    background: #e8f3eb;
    color: #188841;
    padding: 8px 12px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
}

@media (max-width: 1100px) {
    .page-body-wrapper {
        padding: 30px 50px;

        .page-body-content {
            padding: 0px 50px;
        }
    }
}

@media (max-width: 900px) {
    .page-body-wrapper {
        padding: 30px 30px;

        .page-body-content {
            padding: 0px 20px;
        }
    }

}

@media (max-width: 540px) {
    .page-body-wrapper {
        padding: 30px 20px;

        .page-body-content {
            padding: 0px 0px;
        }
    }
}